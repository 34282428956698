<template>
  <div class="update">
    <baseContainer submit cancel @submit="submit">
      <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px">
        <baseSection name="设备信息">
          <baseRow justify="space-between">
            <baseCol>
              <el-form-item label="类型名称" prop="name">
                <el-input v-model.trim="form.name" placeholder="请输入设备类型"></el-input>
              </el-form-item>
            </baseCol>
            <baseCol>
              <el-form-item label="类型代码" prop="code">
                <el-input v-model.trim="form.code" placeholder="请输入类型代码"></el-input>
              </el-form-item>
            </baseCol>
            <baseCol></baseCol>
          </baseRow>
        </baseSection>
        <baseSection name="上传资料">
          <baseRow>
            <baseCol>
              <el-form-item label="类型图标">
                <Upload
                  :setimgUrl="form.iconUrl"
                  @getimgUrl="
                    item => {
                      form.iconUrl = item;
                    }
                  "
                  :currenttype="6"
                  @geticonId="
                    item => {
                      form.iconId = item;
                    }
                  "
                ></Upload>
              </el-form-item>
            </baseCol>
          </baseRow>
        </baseSection>
      </el-form>
    </baseContainer>
  </div>
</template>

<script>
import baseContainer from '@/components/container/base-container';
import baseSection from '@/components/section/base-section';
import baseRow from '@/components/row/base-row';
import baseCol from '@/components/col/base-col';
import Upload from '@/components/upload/uploadimg/index.vue';
export default {
  props: ['deviceTypeId'],
  data() {
    return {
      rules: {
        name: [{ required: true, message: '请输入设备类型', trigger: 'blur' }],
        code: [{ required: true, message: '请输入类型代码', trigger: 'blur' }]
      },
      form: {
        name: '',
        code: '',
        iconId: '',
        iconUrl: ''
      }
    };
  },
  created() {
    this.loadEditData();
  },
  methods: {
    async loadEditData() {
      if (this.deviceTypeId) {
        this.form = await this.$apis.deviceType.get({ deviceTypeId: this.deviceTypeId });
      }
    },
    submit() {
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          const model = this.deviceTypeId ? 'update' : 'save';
          const { fail } = await this.$apis.deviceType[model](this.form);
          if (!fail) {
            this.$router.back();
          }
        }
      });
    }
  },
  components: {
    baseContainer,
    baseSection,
    baseRow,
    baseCol,
    Upload
  }
};
</script>

<style lang="scss" scoped>
.el-input,
.el-select {
  width: 100%;
}
</style>
